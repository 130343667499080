<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="d-flex justify-end mb-6" flat tile>
        <v-breadcrumbs :items="breadcrumbs" large>
          <template v-slot:divider>
            <v-icon>{{ icons.mdiSlashForward }} </v-icon>
          </template>
        </v-breadcrumbs>
      </v-card>
      <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> COMPANY</v-card-title>
          <!-- <v-btn color="accent" class="me-3 mt-4">
            <a href="companys/create">
              <v-icon color="#ECEFF1">
                {{ icons.mdiContentSave }}
              </v-icon>
            </a>
          </v-btn> -->
          <v-text-field class="pt-5" label="Search" @keyup="doSearch()" v-model="search" dense outlined></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">COMPANY NAME</th>
                  <th class="text-center text-uppercase">EMAIL</th>
                  <th class="text-center text-uppercase">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayData()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ data.nama_perusahaan }}</td>
                  <td class="text-center">
                    {{ data.email }}
                  </td>
                  <td class="text-center">
                    <v-btn
                      :to="{
                        name: 'companys-edit',
                        params: { id_pemilik: data.id_pemilik },
                      }"
                      color="warning"
                      class="me-3 mt-4"
                    >
                      <v-icon color="#ECEFF1">
                        {{ icons.mdiGreasePencil }}
                      </v-icon>
                    </v-btn>

                    <!-- <v-btn @click="deletePost(data.companys, index)" color="error" class="mt-4" type="button">
                      <v-icon color="#ECEFF1">
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn> -->
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6">
                    <center>
                      <div class="v-data-footer">
                        <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                        <span class="v-data-footer__icons-before" bis_skin_checked="1">
                          <button
                            type="button"
                            @click="page--"
                            :class="[
                              'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page == 1 ? 'v-btn--disabled' : '',
                            ]"
                            aria-label="Previous page"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                        <span
                          :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                          v-for="pageNumber in pages.slice(page - 1, page + 5)"
                          :key="pageNumber"
                          @click="page = pageNumber"
                        >
                          <!-- <a href="#">{{ pageNumber }}</a> -->
                          <button
                            type="button"
                            class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                            aria-label="Next page"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>
                        <span class="v-data-footer__icons-after" bis_skin_checked="1">
                          <button
                            type="button"
                            :class="[
                              'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page < pages.length ? '' : 'v-btn--disabled',
                            ]"
                            aria-label="Next page"
                            @click="page++"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                      </div>
                    </center>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
} from '@mdi/js'

export default {
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Company',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
      },

      companys: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  created() {
    this.$isLoading(true)
    this.getAllData()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    // deletePost(id, index) {
    //   this.$swal
    //     .fire({
    //       title: 'Apakah anda yakin?',
    //       text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'OK',
    //       cancelButtonText: 'CANCEL',
    //     })
    //     .then(result => {
    //       if (result.value) {
    //         let uriDelete = process.env.VUE_APP_ROOT_API + `/api/pemiliks/${id}`
    //         this.axios
    //           .delete(uriDelete)
    //           .then(response => {
    //             this.companys.splice(this.companys.index, 1)
    //             if (response.status == 200) {
    //               this.$swal.fire({
    //                 title: 'Success!',
    //                 text: 'Role deleted successfully!',
    //                 icon: 'success',
    //                 showCancelButton: false,
    //                 showConfirmButton: false,
    //                 timer: 3000,
    //               })
    //               window.location.reload()
    //             } else {
    //               this.$swal.fire({
    //                 title: 'Error!',
    //                 text: 'Something wrong, cann delete role!',
    //                 icon: 'error',
    //                 showCancelButton: false,
    //                 showConfirmButton: false,
    //                 timer: 3000,
    //               })
    //             }
    //           })
    //           .catch(e => {
    //             console.log(e)
    //             this.$swal.fire({
    //               title: 'Error!',
    //               text: 'Role failed to deleted!',
    //               icon: 'error',
    //               showCancelButton: false,
    //               showConfirmButton: false,
    //               timer: 3000,
    //             })
    //           })
    //       }
    //     })
    // },
    getAllData() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/pemiliks'
      return this.axios.get(url).then(response => {
        this.companys = response.data.pemilik
      })
    },

    doSearch() {
      let go = this.companys.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_perusahaan.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.companys)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.companys.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(companys) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return companys.slice(from, to)
    },
  },

  watch: {
    companys() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
